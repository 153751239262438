<!-- 新闻动态 -->
<template>
    <div class="NewsDynamic">
        <!-- 查询表单 -->
        <el-form ref="form" :model="form" inline class="form" label-width="100px">
            <el-form-item label="标题">
                <el-input v-model="form.title" placeholder="请输入标题"></el-input>
            </el-form-item>
            <el-form-item label="查询日期">
                <el-date-picker value-format="yyyy-MM-dd" v-model="form.dateQuery" type="daterange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="状态">
                <el-select v-model="form.status" placeholder="请选择" :clearable="true">
                    <el-option v-for="item in form.statusOpt" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="">
                <div class="" style="margin-right: 10px;">
                    <el-button size="small" style="background: #18BC9C;color: #FFFFFF;" @click="Query">
                        查询
                    </el-button>
                    <el-button size="small" style="background: #FAFAFA;color: #666666;" @click="Reset">
                        重置
                    </el-button>
                </div>
            </el-form-item>
        </el-form>
        <div class="" style="margin-left:22px ;">
            <!-- 刷新 -->
            <el-button size="small" icon="el-icon-refresh" style="background-color: #2C3E50; border-radius: 3px;"
                @click="refresh">
            </el-button>
            <el-button size="small" icon="el-icon-plus" style="background: #18BC9C;color: #FFFFFF;"
                @click="newlyAdded">
                新增
            </el-button>
        </div>
        <!-- 表格 -->
        <el-table :data="tableData" border stripe style="width:97.9%;flex: 1;" height="650">
            <el-table-column type="index" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="" label="标题" align="center" width="450">
            </el-table-column>
            <el-table-column prop="" label="简介" align="center" width="300">
            </el-table-column>
            <el-table-column prop="" label="浏览量" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" label="添加时间" align="center" width="150">
            </el-table-column>
            <el-table-column prop="" label="发布时间" align="center" width="150">
            </el-table-column>
            <el-table-column prop="" label="状态" align="center" width="120">
            </el-table-column>
            <el-table-column prop="" label="操作人" align="center" width="150">
            </el-table-column>
            <el-table-column label="操作" align="center" fixed="right" width="240">
                <template slot-scope="scope">
                    <el-button size="mini" icon="el-icon-edit" class="edit"
                        @click="edit(scope.$index, scope.row)">
                        编辑
                    </el-button>
                    <el-button size="mini" type="danger" icon="el-icon-delete" 
                        @click="delt(scope.$index, scope.row)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[10,30,50,100,200,500]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                    title:"",//标题
                    dateQuery:"",//查询时间
                    status:"",
                    statusOpt:[
                        {label:"显示",value:1},
                        {label:"不显示",value:2}
                    ],//状态选择
                },
                tableData:[{label:"显示",value:1},], //表格数据
                currentPage: 1,
                currentLimit: 10,//条数
                total: 0,
            }
        },
        created() {
            
        },
        methods:{
            handleSizeChange(val) {
                // console.log(`每页 ${val} 条`);
                // this.currentLimit = val
                // this.getList()
            },
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`);
                // this.currentPage = val
                // this.getList()
            },
            //刷新
            refresh() {
                this.$router.push({
                    path: "/home/newpage", //空页面路由
                    query: {
                        path: this.$route.path
                    }
                })
            },
            newlyAdded() { //新增
                this.$router.push({
                    path: "addnewsDynamic"
                })
            },
            Query(){//查询
                
            },
            Reset(){//重置
                this.form.title=""//标题
                this.form.dateQuery=""//查询时间
                this.form.status=""//状态
            },
            edit(index,row){//编辑
                this.$router.push({
                    path: "editnewsDynamic", 
                    query: {
                        id: row.id
                    }
                })
            },
            delt(index,row){//删除
                
            }
        }
    }
</script>

<style scoped="scoped">
    .NewsDynamic{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 查询部分 */
    .form {
        padding-top: 34px;
        display: flex;
        flex-wrap: wrap;
    }
    
    .form ::v-deep .el-input__inner {
        width: 276px;
        height: 37px;
    }
    
    .form ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    /* 按钮 */
    ::v-deep .el-icon-refresh {
        font-weight: bold !important;
        color: #FFFFFF;
    }
    
    ::v-deep .el-icon-plus {
        font-weight: bold !important;
    }
    /* 表格 */
    .el-table {
        margin: 20px;
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 35px;
    }
    .edit{
        background: #18BC9C;
        color: #fff;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #2C3E50 !important;
    }
</style>
